import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var gtag: Function;
@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    // Parameter	-Description	                              -  Example Values
    // action	    -Describes the user action	                  -  'click', 'submit', 'purchase'
    // category	    -Groups events into categories	              -  'user_interaction', 'form_submission'
    // label	    -Additional contextual information	          -  'sign_up_button', 'login_form'
    // value	    -A numeric value associated with the event    -	 1, 100, 25.50

    trackEvent(action: string, category: string, label: string, value: number = 1): void {
        if (typeof gtag !== 'undefined') {
            gtag('event', action, {
                event_category: category,
                event_label: label,
                value: value,
                debug_mode: !environment.production 
            });
            console.log('Event sent to Google Analytics:', { action, category, label, value });
        } else {
            console.error('gtag is not defined');
        }
    }
}